import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import Title from "./Title";
import property_pic from "../assets/property.jpg";
import project_pic from "../assets/project.jpg";
import sales_pic from "../assets/sales.png";
import human_resource_pic from "../assets/human_resource.jpg";
import financial_mgt from "../assets/financial_mgt.jpg";
import office_pic from "../assets/office.jpg";
import { PROJECTS_BASE_URL } from "../constants/companyInfo";

const cardStyle = {
  maxWidth: 345,
  height: "100%",
  cursor: "pointer",
  "&:hover": {
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
  },
};

export default function ProjectSection() {
  return (
    <Container sx={{ my: 5 }}>
      <Stack spacing={5}>
        <Title title="Projects" color="secondary" />

        <Grid container>
          <Grid item xs={12} sm={6} md={4} mb={5}>
            <a
              href={`https://property.${PROJECTS_BASE_URL}`}
              style={{ textDecoration: "none" }}
            >
              <Card sx={cardStyle}>
                <CardMedia
                  component="img"
                  height="170"
                  image={property_pic}
                  alt="property management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Property & Proc. Mgmt.
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={4} mb={5}>
            <a
              href={`https://pm.${PROJECTS_BASE_URL}`}
              style={{ textDecoration: "none" }}
            >
              <Card sx={cardStyle}>
                <CardMedia
                  component="img"
                  height="170"
                  image={project_pic}
                  alt="project management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Project Mgmt.
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={4} mb={5}>
            <a href="#" style={{ textDecoration: "none" }}>
              <Card sx={cardStyle}>
                <CardMedia
                  component="img"
                  height="170"
                  image={sales_pic}
                  alt="Sales management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Production & Sales Mgmt
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <a
              href={`https://office.${PROJECTS_BASE_URL}`}
              style={{ textDecoration: "none" }}
            >
              <Card sx={cardStyle}>
                <CardMedia
                  component="img"
                  height="170"
                  image={office_pic}
                  alt="office management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Office Mgmt
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <a href="#" style={{ textDecoration: "none" }}>
              <Card sx={cardStyle}>
                <CardMedia
                  component="img"
                  height="170"
                  image={human_resource_pic}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Human Resource Mgmt
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <a href="#" style={{ textDecoration: "none" }}>
              <Card sx={cardStyle}>
                <CardMedia
                  component="img"
                  height="170"
                  image={financial_mgt}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Financial Mgmt
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}
