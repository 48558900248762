import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { companyInfo } from "../constants/companyInfo";

export default function NavBar() {
  return (
    <Box
      sx={{
        background:
          "radial-gradient(circle, hsla(0, 0%, 86%, 1) 0%, hsla(257, 23%, 12%, 1) 100%)",
        py: 1,
      }}
    >
      <Container>
        <Avatar
          variant="rounded"
          src={companyInfo?.logo}
          sx={{
            mx: "auto",
            mb: 1,
            width: { xs: 120, sm: 150 },
            height: { xs: 60, sm: 90 },
          }}
        />
        <Typography variant="h6" color="secondary" textAlign="center">
          AV
        </Typography>
      </Container>
    </Box>
  );
}
