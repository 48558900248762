import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import NavBar from "./components/NavBar";
import ProjectSection from "./components/ProjectSection";
import AboutUsSection from "./components/AboutUsSection";
import StepsSection from "./components/StepsSection";
import FooterSection from "./components/FooterSection";

const theme = createTheme({
  palette: {
    background: {
      default: "#F1F1F1",
    },
    primary: {
      main: "#DBF227",
    },
    secondary: {
      main: "#1B1725",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <ProjectSection />
      <AboutUsSection />
      <StepsSection />
      <FooterSection />
    </ThemeProvider>
  );
}

export default App;
