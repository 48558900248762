import logo from "../assets/logo.png";
import constructionPic from "../assets/construction_pic_1.png";

export const companyInfo = {
  logo,
  constructionPic,
  website: "www.avalueeng.com",
  email: "",
  phone: "",
  phoneLabel: "",
  location: "",
  locationUrl: "",
  aboutUs:
    "AV Engineering and Construction plc is a building construction firm in the country with good capacity and has engaged in different types of building construction works with in short time which helps the company realize what its target and vision should be. Our goal as a company is to meet the clients' interest by delivering the right resources at the right time with our qualified and trust worthy staff.",
};

export const PROJECTS_BASE_URL = "averp.org";
